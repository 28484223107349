import * as React from 'react';
import styled, { css } from 'styled-components';
import { useContext } from 'react';

import { widgetHeaderId } from 'common/components/widgetRenderers/Heading';
import type { WidgetRef } from 'report/types';
import WidgetDetailsContext from 'report/report-creation/WidgetDetailsContext';
import { widgetTitle } from 'report/report-render-page/ReportWidgets';

const Container = styled.div`
  margin-top: 40px;
  margin-bottom: 200px;
`;

const TocLi = styled.li(({ theme }) => css`
  &:not(:last-child) {
    margin-bottom: 6px;
  }

  a {
    width: 100%;
    text-decoration: none;
    color: ${theme.colors.text.primary};
    display: block;
  }
`);

type Props = {
  handleTocClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void,
  widgets: Array<WidgetRef>;
}

const Toc = ({ widgets, handleTocClick }: Props) => {
  const { widgets: widgetDetails } = useContext(WidgetDetailsContext);

  return (
    <Container>
      <h3 className="has-bm">Table of Contents</h3>
      <ol>
        {widgets.map((widget) => {
          const completeWidget = widgetDetails[widget.widgetId];
          const title = widgetTitle(widget, completeWidget);

          return (
            <TocLi>
              <a href={`#${widgetHeaderId(widget.widgetId)}`} onClick={handleTocClick}>
                {title}
              </a>
            </TocLi>
          );
        })}
      </ol>
    </Container>
  );
};

Toc.defaultProps = {
  handleTocClick: undefined,
};

export default Toc;
